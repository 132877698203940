import { reactive, toRefs } from "vue";
const __default__ = {
  props: {
    showSelection: {
      type: Boolean,
      default: false
    },
    tableHeight: {
      type: String,
      default: '600px'
    },
    tableData: {
      type: Array,
      default: () => []
    },
    border: {
      default: true
    },
    showHeader: {
      default: true
    }
  },
  setup(props, context) {
    const state = reactive({
      multipleSelection: ""
    });
    const handleSelectionChange = val => {
      state.multipleSelection = val;
      context.emit("handleSelect", JSON.parse(JSON.stringify(val)));
    };
    return {
      ...toRefs(state),
      handleSelectionChange
    };
  }
};
import { useCssVars as _useCssVars } from 'vue';
const __injectCSSVars__ = () => {
  _useCssVars(_ctx => ({
    "279ac17f": _ctx.tableHeight
  }));
};
const __setup__ = __default__.setup;
__default__.setup = __setup__ ? (props, ctx) => {
  __injectCSSVars__();
  return __setup__(props, ctx);
} : __injectCSSVars__;
export default __default__;