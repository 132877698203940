import { createVNode, render } from 'vue'
import TcMessage from '@/components/tc-message'

// 准备dom容器
const div = document.createElement('div')
div.setAttribute('class', 'message-container')
document.body.appendChild(div)

let timer = null

export default ({ type, text, wait=1000 }) => {
    const vnode = createVNode(TcMessage, { type, text })
    render(vnode, div)
    clearTimeout(timer)
    if (type == "success" ) {
        timer = setTimeout(() => {
            render(null, div)
        }, wait)
    }else if(type == "error"){
        timer = setTimeout(() => {
            render(null, div)
        }, wait)
    }

}

