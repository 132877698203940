const user = {
    namespaced: true,
    state: {
        userRouters: [],
        selectPart: localStorage.getItem("selectPart"),
        activeItem: localStorage.getItem("activeItem") || '仓库数据',
        localLanguage: localStorage.getItem("language") || 'zh',
        printer: localStorage.getItem("printer"),
        
        authCenters: [],//用户一级权限
        userBtns: [],//用户按钮权限
    },
    getters: {

    },
    mutations: {
        addUserRouters(state, data) {
            state.userRouters.push(data)
        },
        addAuthCenters(state, data) {
            state.authCenters = data
        },
        addUserBtns(state, data) {
            state.userBtns = data
        },
        changeSelectPart(state, data) {
            state.selectPart = data
        },
        clearUserRouters(state) {
            state.userRouters = []
        },
        changeActiveItem(state, data) {
            state.activeItem = data
        },
        changeLocalLanguage(state, data) {
            state.localLanguage = data
        },
        changePrinter(state, data) {
            state.printer = data
        },

    },
    actions: {

    },
    modules: {

    }
}
export default user