const operateCenter = {
    namespaced: true,
    state: {
        warehousingManagementState: {},
        outWarehouseManagementState: {},
        orderManagementState: {},
        containerManagementState: {},
        deliveryReservationState: {},
        warehousingAbnormalState: {},
        outWarehouseAbnormalState: {},
        dropShippingOutwarehouseState: {},
    },
    getters: {
    },
    mutations: {
        setWarehousingManagementState(state, data) {
            state.warehousingManagementState = data
        },
        setOutWarehouseManagementState(state, data) {
            state.outWarehouseManagementState = data
        },
        setOrderManagementState(state, data) {
            state.orderManagementState = data
        },
        setContainerManagementState(state, data) {
            state.containerManagementState = data
        },
        setDeliveryReservationState(state, data) {
            state.deliveryReservationState = data
        },
        setWarehousingAbnormalState(state, data) {
            state.warehousingAbnormalState = data
        },
        setOutWarehouseAbnormalState(state, data) {
            state.outWarehouseAbnormalState = data
        },
        setDropShippingOutwarehouseState(state, data) {
            state.dropShippingOutwarehouseState = data
        },
    },
    actions: {

    }
}

export default operateCenter