import axios from "@/utils/axios/axios";

// 登录
export function userLoginAPI(data) {
  return axios({
    url: 'mx/v1/admin/Login/login',
    method: 'POST',
    data
  })
}

// 退出登录
export function logoutAPI(data) {
  return axios({
    url: 'mx/v1/admin/login/logout',
    method: 'POST',
    data
  })
}

// 切换多语言
export function updateLanguageAPI(data) {
  return axios({
    url: 'mx/v1/admin/user/updateLanguage',
    method: 'POST',
    data
  })
}

// 公司仓库列表
export function privateWarehouseListAPI(data) {
  return axios({
    url: 'mx/v1/operation/Category/privateWarehouseList',
    method: 'POST',
    data
  })
}

// 运输类型
export function transportTypeAPI(data) {
  return axios({
    url: 'mx/v1/operation/Category/transportType',
    method: 'POST',
    data
  })
}
// 获取部门分类列表
export function getStructureTypeListAPI(data) {
  return axios({
    url: 'mx/v1/admin/auth/structure/getStructureTypeList',
    method: 'post',
    data
  })
}
// 获取负责人列表
export function getUserNameListAPI(data) {
  return axios({
    url: 'mx/v1/admin/auth/user/getUserNameList',
    method: 'post',
    data
  })
}

// 获取部门列表
export function getDepartmentListAPI(data) {
  return axios({
    url: 'mx/v1/admin/auth/structure/getList',
    method: 'post',
    data
  })
}
// 获取权限列表
export function getUserAllRuleTreeListAPI(data) {
  return axios({
    url: 'mx/v1/admin/auth/rule/getUserAllRuleTreeList',
    method: 'post',
    data
  })
}
// 获取按钮权限
export function getUserAllButtonRuleKeyListAPI(data) {
  return axios({
    url: 'mx/v1/admin/auth/rule/getUserAllButtonRuleKeyList',
    method: 'post',
    data
  })
}
// oss域名
export function getOssHttpSrcAPI(data) {
  return axios({
    url: 'mx/v1/HttpSrc/ossHttpSrc',
    method: 'post',
    data
  })
}


// 获取运单仓库货物的操作-日志列表
export function getGoodsOperateLogListAPI(data) {
  return axios({
    url: 'mx/v1/operation/goodsOperationLog/getLogList',
    method: 'POST',
    data,
  })
}
// 获取运单仓库货物的操作- 日志行为（动作）
export function getGoodsOperateActionListAPI(data) {
  return axios({
    url: 'mx/v1/operation/goodsOperationLog/getActionList',
    method: 'POST',
    data,
  })
}
// 更新排序
export function updateFieldSortAPI(data) {
  return axios({
    url: 'mx/v1/admin/FieldSort/updateFieldSort',
    method: 'POST',
    data,
  })
}
// 字段对应排序列表
export function getFieldSortListAPI(data) {
  return axios({
    url: 'mx/v1/admin/FieldSort/getFieldSortList',
    method: 'POST',
    data,
  })
}
// 获取客户名称列表
export function getCustomerNameListAPI(data) {
  return axios({
    url: 'mx/v1/operation/customer/getCustomerNameList',
    method: 'POST',
    data,
  })
}
// 获取基础选项
export function getBasicOptionAPI(data) {
  return axios({
    url: 'mx/v1/operation/Category/getBasicOption',
    method: 'POST',
    data,
  })
}
// 币种列表
export function currencyListAPI(data) {
  return axios({
    url: 'mx/v1/Category/currencyList',
    method: 'POST',
    data,
  })
}
// 国家列表
export function countryListAPI(data) {
  return axios({
    url: 'mx/v1/Category/countryList',
    method: 'POST',
    data,
  })
}

// 更新排序
export function submitFieldSortAPI(data) {
  return axios({
    url: 'mx/v1/admin/FieldSort/updateFieldSort',
    method: 'POST',
    data,
  })
}
// 字段对应排序列表
export function fieldSortListAPI(data) {
  return axios({
    url: 'mx/v1/admin/FieldSort/getFieldSortList',
    method: 'POST',
    data,
  })
}
// 获取客户名称列表
export function getNameListAPI(data) {
  return axios({
    url: 'mx/v1/data_sync/home/customer/getNameList',
    method: 'POST',
    data,
  })
}
// 获取客户详情
export function getCustomerDetailAPI(data) {
  return axios({
      url: 'mx/v1/data_sync/home/customer/getCustomerDetail',
      method: 'post',
      data
  })
}
// 获取客户sku名称列表-（不分页）
export function getSKUNameListAPI(data) {
  return axios({
      url: 'mx/v1/data_sync/home/customer/sku/getNameList',
      method: 'POST',
      data
  })
}
// 获取负责人列表2
export function getUserNameListAPI2(data) {
  return axios({
    url: 'mx/v1/data_sync/home/adminUser/getUserNameList',
    method: 'post',
    data
  })
}
// 订单状态列表
export function getOrderStatusListAPI(data) {
  return axios({
      url: 'mx/v1/order/transferWarehouse/detail/getOrderStatusList',
      method: 'POST',
      data
  })
}
// 获取负责人/客户经理列表
export function userListAPI(data) {
  return axios({
    url: 'mx/v1/admin/auth/user/getUserNameList',
    method: 'post',
    data
  })
}
// 平台类型
export function getShipmentPlatformListAPI(data) {
  return axios({
      url: 'mx/v1/operation/order/shipment/getShipmentTypeList',
      method: 'POST',
      data
  })
}
// 客户信息2/客户联系人
export function getMemberListAPI(data) {
  return axios({
      url: 'mx/v1/data_sync/home/customer/getMemberList',
      method: 'post',
      data
  })
}
// 获取预留尺寸列表-打印机
export function getReservePrintSizeListAPI(data) {
  return axios({
      url: 'mx/v1/admin/printer/getReservePrintSizeList',
      method: 'post',
      data
  })
}

// 获取打印测试PDF-打印机
export function getPrintTestPdfAPI(data) {
  return axios({
      url: 'mx/v1/admin/printer/getPrintTestPdf',
      method: 'post',
      data
  })
}