import TcMsg from "./tc-message"

export default (url,name)=>{
    const link = document.createElement('a')
    link.style.display = 'none'
    link.href = url
    link.target = "_blank"
    link.download = name
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    TcMsg({ type: 'success', text: "已添加至下载列表" })

}