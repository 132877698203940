import { BigNumber } from 'bignumber.js'

// a+b
export function add(a, b) {
    a = BigNumber(a)
    b = BigNumber(b)
    return a.plus(b).toNumber()
}

// a-b
export function minus(a, b) {
    a = BigNumber(a)
    b = BigNumber(b)
    return a.minus(b).toNumber()
}

// a*b
export function mutiply(a, b) {
    a = BigNumber(a)
    b = BigNumber(b)
    return a.multipliedBy(b).toNumber();
}

// a/b
export function devide(a, b) {
    a = BigNumber(a)
    b = BigNumber(b)
    return a.dividedBy(b).toNumber();
}

// 多个数求和
export function multiAdd(...params) {
    let data = BigNumber(0);
    for (let index = 0; index < params.length; index++) {
        const element = BigNumber(params[index])
        data = data.plus(element)
    }
    return data.toNumber()
}
